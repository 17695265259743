import React, {useState} from 'react';
import './../css/webflow.css';
import './../css/beni2.css';
import './../css/normalize.css';
import { useAuth } from '../mainpagecomp/AuthContext';
import { useCart } from '../cart/CartContext';
import foursesImg from '../../assets/images/fourseasgrey.png';
import winterImg from '../../assets/images/wintergrey.png';
import summerImg from '../../assets/images/summergrey.png';
import gaspumpImg from '../../assets/images/gaspump.png';
import noimageImg from '../../assets/images/noimage.png';
import rainImgsrc from '../../assets/images/rain.png';
import volumeImg from '../../assets/images/volume.png';

const KosarEmptyWrap = () => {
    const {isNavOpen,changeNav,selectPage,logged} = useAuth();
    const {cartData,calculateTotal,removeBasketData} = useCart();

    const getImageUrl = (item) => {
        return item.imgurl === "N/A" ? noimageImg : item.imgurl;
    };


    // Function to get season image
    const getSeasonImg = (season) => {
        switch (season) {
            case 1:
                return winterImg;
            case 2:
                return summerImg;
            default:
                return foursesImg;
        }
    };

    // Function to get season text
    const getSeason = (season) => {
        switch (season) {
            case 1:
                return "Téli";
            case 2:
                return "Nyári";
            default:
                return "Négyévszakos";
        }
    };

    const removeItem = (index) => {
      removeBasketData(index)
    }


    return (
        <div class="ureskosarwrap">
      <div class="ureskosar">
        <div class="sajatbevasarlokosartextholder"></div>
        <div class="sajatkosardiv">
          <div class="uresbevasarlokosartext">Saját bevásárlókosár</div>
        </div>
        <div class="kosarcsik"></div>
        <div class="ureskosaruresdiv11">
          <div class="ureskosardiv">
          <div className='content1'>
            {logged ? (
              cartData.products && cartData.products.length > 0 ? (
                <div className="data-section11">
                  {cartData.products.map((product, index) => (
                    <div className="basket-item11" key={product.id}>
                      <div className="basket-left">
                        <div className="card-image-container31">
                          <img className="card-image3" src={getImageUrl(product)} alt="product" onError={(e) => e.target.style.display = 'none'} />
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="basket-right1">
                        <button className="remove-button" onClick={() => removeItem(product.cikkszam)}>X</button>
                        <div>Név: {product.mintazat}</div>
                        <div>Mennyiség: {cartData.amounts[index]}</div>
                        <div>Ár: {product.formazottAr} Ft</div>
                        <div>Total: {Intl.NumberFormat('de-DE').format(product.eladasiar * cartData.amounts[index])} Ft</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <div className="text-block-40">A kosár üres.</div>
                  <a href="#" className="vasarlasfolytataslink w-inline-block">
                    <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                  </a>
                </div>
              )
            ) : (
              <div style={{justifyContent:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div className="text-block-40">Jelentkezz be a vásárláshoz.</div>
                <a href="#" className="vasarlasfolytataslink w-inline-block">
                  <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                </a>
              </div>
            )}
          </div>
          </div>
        </div>
        <div class="ureskosarcsik2"></div>
        <div class="ureskosargombdiv">
          <a href="#" class="bongeszesgomb w-button" onClick={()=>selectPage("Termek")}>Tovább böngészek!</a>
        </div>
      </div>
    </div>
    );
};
export default KosarEmptyWrap;