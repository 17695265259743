import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import foursesImg from '../../assets/images/fourseasgrey.png';
import winterImg from '../../assets/images/wintergrey.png';
import summerImg from '../../assets/images/summergrey.png';
import gaspumpImg from '../../assets/images/gaspump.png';
import noimageImg from '../../assets/images/noimage.png';
import rainImgsrc from '../../assets/images/rain.png';
import volumeImg from '../../assets/images/volume.png';
import { useTermin } from '../termkemintacomp/TerminContext';
import { useAuth } from '../mainpagecomp/AuthContext';

// Define the component accepting `items` as props
const TermekCardContainer = ({ items }) => {
    const {data,loadData} = useTermin();
    const {selectPage} = useAuth();
    const getImageUrl = (item) => {
        return item.imgurl === "N/A" ? noimageImg : item.imgurl;
    };

    // Function to get season image
    const getSeasonImg = (season) => {
        switch (season) {
            case 1:
                return winterImg;
            case 2:
                return summerImg;
            default:
                return foursesImg;
        }
    };

    // Function to get season text
    const getSeason = (season) => {
        switch (season) {
            case 1:
                return "Téli";
            case 2:
                return "Nyári";
            default:
                return "Négyévszakos";
        }
    };

    // Function to get stock color based on the quantity
    const getStockColor = (num) => {
        if (num > 4) return "green";
        else if (num > 0) return "orange";
        else return "red";
    };

    // Function to get stock text
    const getStockText = (num) => {
        if (num > 4) return `Raktáron: (${num} db)`;
        else if (num > 0) return `Utolsó ${num} db`;
        else return "Elfogyott";
    };

    const fetchData = async (cikkszam) => {
        const response = await fetch(`http://95.138.193.55:41809/productId?cikkszam=${cikkszam}`);
        const result = await response.json();
        loadData(result);
        window.scrollTo(0, 0);
        selectPage("Termin");
    };

    // Render the cards using map over the items array
    return (
        <div id="Allcardcontainer" style={ {flexFlow: 'wrap', width:'100vw',justifyContent:"center",display: 'flex', transform: 'translate(0, -160px)'}}>
            {items.map(item => (
                <div key={item.cikkszam} className="card">
                    <div className="card-image-container">
                        <img className="card-image" src={getImageUrl(item)} alt="product" onError={(e) => e.target.style.display = 'none'} />
                    </div>
                    <hr className="divider" />
                    <div className="info-section grey-background">
                        <img src={getSeasonImg(item.szezon)} style={{ maxHeight: '16px', maxWidth: '16px', transform: "translateY(1px)" }} />
                        <div>{getSeason(item.szezon)}</div>
                        <img src={gaspumpImg} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2px)" }} />
                        <div>{item.fogyasztas || 'N/A'}</div>
                        <img src={rainImgsrc} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2.5px)" }} />
                        <div>{item.fekezes || 'N/A'}</div>
                        <img src={volumeImg} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2px)" }} />
                        <div>{item.gordZaj || 'N/A'}</div>
                    </div>
                    <div className="info-section">
                        <h3 style={{ fontSize: '22px', marginBottom: "5px", fontWeight: "800", fontStyle: "italic" }}>{item.gyartoMegnevezes}</h3>
                        <div style={{ fontSize: "14px", color: "#28353e", marginTop: "5px" }}>{item.mintazat}</div>
                        <div style={{ fontSize: "14px", color: "#28353e", marginTop: "5px", height: "40px" }}>{item.formazottmegnevezes}</div>
                        <div style={{ fontSize: "12px", fontWeight: "600", color: "#a4a4a4", marginTop: "10px", marginBottom: "5px" }}>{item.cikkcsoportMegnevezes}</div>
                        <div style={{ marginBottom: "30px", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px" }}>
                            <div style={{ height: "8px", width: "8px", backgroundColor: getStockColor(item.keszlet), borderRadius: "20px" }}></div>
                            <div style={{ marginLeft: "10px", color: getStockColor(item.keszlet) }}>{getStockText(item.keszlet)}</div>
                        </div>
                        <div style={{ fontSize: "28px", fontWeight: "800", fontStyle: "italic", color: "#bb0808", marginBottom: "10px" }}>{`${item.formazottAr} Ft`}</div>
                    </div>
                    <button href="#" onClick={()=>fetchData(item.cikkszam)} className="buy-button" style={{ color: "#fff", backgroundColor: "#bb0808", borderColor: "#6e0000", boxShadow: "0 4px 0 #6e0000", display: "block", width: "100%", fontWeight: "700", textAlign: "center", verticalAlign: "middle", border: "1px solid transparent", padding: "10px 35px", fontSize: "1rem", lineHeight: "1.5", borderRadius: "5px", transition: "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out" }}>
                        Kosárba
                    </button>
                </div>
            ))}
        </div>
    );
};

export default TermekCardContainer;