import React, {useState} from 'react';
import './../css/webflow.css';
import './../css/succes.css';
import './../css/normalize.css';
import { useAuth } from '../mainpagecomp/AuthContext';
import { useCart } from '../cart/CartContext';
import noimageImg from '../../assets/images/noimage.png';


const SuccesTermekWrap = () => {
    const {isNavOpen,changeNav,selectPage,logged} = useAuth();
    const {cartData,calculateTotal,removeBasketData,szallitas} = useCart();

    const getImageUrl = (item) => {
        return item.imgurl === "N/A" ? noimageImg : item.imgurl;
    };

    return (
        <div class="ureskosarwrap1">
      <div class="ureskosar1">
        <div class="sajatbevasarlokosartextholder1"></div>
        <div class="sajatkosardiv1">
          <div class="uresbevasarlokosartext1">Sikeres vásárlás!</div>
        </div>
        <div class="kosarcsik1"></div>
        <div class="ureskosaruresdiv1">
          <div class="ureskosardiv1">
          <div className='content1'>
          {logged ? (
              cartData.products && cartData.products.length > 0 ? (
                <div className="data-section11">
                  {cartData.products.map((product, index) => (
                    <div className="basket-item11" key={product.id}>
                      <div className="basket-left">
                        <div className="card-image-container31">
                          <img className="card-image3" src={getImageUrl(product)} alt="product" onError={(e) => e.target.style.display = 'none'} />
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="basket-right1">
                        <div>Név: {product.mintazat}</div>
                        <div>Mennyiség: {cartData.amounts[index]}</div>
                        <div>Ár: {product.formazottAr} Ft</div>
                        <div>Total: {Intl.NumberFormat('de-DE').format(product.eladasiar * cartData.amounts[index])} Ft</div>
                      </div>
                    </div>
                  ))}
                  <div style={{marginLeft:"50px",fontSize:"25px",fontWeight:"700",color:"#bb0808"}}>{Intl.NumberFormat('fr-FR').format(szallitas+calculateTotal(cartData))} Ft</div>
                </div>
              ) : (
                <div>
                  <div className="text-block-40">A kosár üres.</div>
                  <a href="#" className="vasarlasfolytataslink w-inline-block">
                    <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                  </a>
                </div>
              )
            ) : (
              <div style={{justifyContent:'center',display:'flex',flexDirection:'column',alignItems:'center',marginTop:"115px"}}>
                <div className="text-block-40">Jelentkezz be a vásárláshoz.</div>
                <a href="#" className="vasarlasfolytataslink w-inline-block">
                  <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                </a>
              </div>
            )}
          </div>
          </div>
        </div>
        <div class="ureskosarcsik2"></div>
        <div class="ureskosargombdiv">
          <a href="#" class="bongeszesgomb w-button" onClick={()=>selectPage("Termek")}>Tovább böngészek!</a>
          <a href="#" class="bongeszesgomb w-button" onClick={()=>selectPage("Termek")}>Bizonylat mentése!</a>
        </div>
      </div>
    </div>
    );
};
export default SuccesTermekWrap;