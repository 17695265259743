import React from 'react';
import './../css/webflow.css';
import './../css/beni2.css';
import './../css/normalize.css';
import { useAuth } from '../mainpagecomp/AuthContext';
import { useCart } from '../cart/CartContext';
import foursesImg from '../../assets/images/fourseasgrey.png';
import winterImg from '../../assets/images/wintergrey.png';
import summerImg from '../../assets/images/summergrey.png';
import gaspumpImg from '../../assets/images/gaspump.png';
import noimageImg from '../../assets/images/noimage.png';
import rainImgsrc from '../../assets/images/rain.png';
import volumeImg from '../../assets/images/volume.png';
import { useTermin } from '../termkemintacomp/TerminContext';

const KosarTermek = () => {
  const {isNavOpen,changeNav,selectPage,logged} = useAuth();
  const {cartData,calculateTotal,removeBasketData,addBasketData,minusBasketData,szallitas} = useCart();
  const {openedPay,togglePay,payPrice,setPrice} = useTermin();

  const getImageUrl = (item) => {
    return item.imgurl === "N/A" ? noimageImg : item.imgurl;
};


// Function to get season image
const getSeasonImg = (season) => {
    switch (season) {
        case 1:
            return winterImg;
        case 2:
            return summerImg;
        default:
            return foursesImg;
    }
};

// Function to get season text
const getSeason = (season) => {
    switch (season) {
        case 1:
            return "Téli";
        case 2:
            return "Nyári";
        default:
            return "Négyévszakos";
    }
};

const removeItem = (index) => {
  removeBasketData(index)
}
const minusItem = (cikk) => {
  minusBasketData(cikk,1);
}
const plusItem = (cikk) => {
  addBasketData(cikk,1)
}

const payButton = (price) => {
  if (price > 100) {
    togglePay();
    setPrice(price);
    console.log("setprice = "+price);
  }
}

    return (
        <div class="kosartermekkelwrap">
      <div class="kosarcontainer">
        <div class="kosartermekkel">
          <div class="bevasarlokosar">
            <div class="sajatbevasarlokosartextholder">
              <div class="sajatbevasarlokosartext">Saját bevásárláskosár</div>
            </div>
            <div class="kosarcsik"></div>
                <div class="infok" style={{display:'flex',alignItems:'center' , justifyContent:'center'}}>
                {logged ? (
                      cartData.products && cartData.products.length > 0 ? (
                        <div className="data-section2">
                          {cartData.products.map((product, index) => (
                            <div className='content2'>
                              <div class="idejonakep"><img className="card-image3" src={getImageUrl(product)} alt="product" onError={(e) => e.target.style.display = 'none'} /></div>
                              <div class="nevarstb">
                              <div className="basket-right2">
                                <div>Név: {product.mintazat}</div>
                                <div>Ár: {product.formazottAr} Ft</div>
                                <div>Total: {Intl.NumberFormat('de-DE').format(product.eladasiar * cartData.amounts[index])} Ft</div>
                              </div>
                              </div>
                              <div class="mennyisegkosar">
                                <div class="kosarmennyiseg w-form">
                                  <form id="email-form" name="email-form" data-name="Email Form" method="get" class="mennyieg1" data-wf-page-id="662abdfc548b4eebcfaf04b7" data-wf-element-id="b10f4bfa-c94e-26a5-af6f-c612b20b6ddf">
                                    <a onClick={()=>minusItem(product.cikkszam)} class="gomb1 w-button">-1</a>
                                    <input value={cartData.amounts[index]} class="mennyiegfr w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="4" type="text" id="field-2" required=""/>
                                    <a onClick={()=>plusItem(product.cikkszam)} class="gomb2 w-button">+1</a>
                                  </form>
                                  <div class="success-message w-form-done"></div>
                                  <div class="error-message w-form-fail"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <div className="text-block-40">A kosár üres.</div>
                          <a href="#" className="vasarlasfolytataslink w-inline-block">
                            <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                          </a>
                        </div>
                      )
                    ) : (
                      <div style={{justifyContent:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="text-block-40">Jelentkezz be a vásárláshoz.</div>
                        <a href="#" className="vasarlasfolytataslink w-inline-block">
                          <div className="text-block-41" onClick={()=>selectPage("Termek")}>Böngészés folytatása.</div>
                        </a>
                      </div>
                    )}
                </div>
            <div class="kosarcsik"></div>
            <div class="text-block-39">Promóciós kód </div>
            <div class="promokod">
              <div class="form-block-4 w-form">
                <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" class="form-6" data-wf-page-id="662abdfc548b4eebcfaf04b7" data-wf-element-id="42e15654-bf50-5d09-266b-91afa0c7e6ad"><input class="promokodinput w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="Adj meg egy promóciós kódot" type="text" id="field-3" required=""/><input type="submit" data-wait="Please wait..." class="promokodbutton w-button" value="Alkalmaz"/></form>
                <div class="w-form-done"></div>
                <div class="w-form-fail"></div>
              </div>
            </div>
            <div class="megjegyzesdiv">
              <div class="form-block-5 w-form">
                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" class="form-7" data-wf-page-id="662abdfc548b4eebcfaf04b7" data-wf-element-id="f1c5b58a-46fa-8ee5-4e38-af0d13ddbc3b">
                  <div class="megjegyzestext">Megjegyzés írása</div>
                  <div class="megjegyzeswrap"><textarea required="" placeholder="Különleges igények? Ird be ide!" maxlength="5000" id="field-4" name="field-4" data-name="Field 4" class="megjegyzes w-input"></textarea></div>
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="megrendelesosszesito">
            <div class="osszesitotextdiv">
              <div class="megrendelesosszefoglalo">Megrendelés összesítő</div>
            </div>
            <div class="kosarcsik"></div>
            <div class="reszosszegdiv">
              <div class="reszosszegtext">Részösszeg</div>
              <div class="artext">{Intl.NumberFormat('fr-FR').format(calculateTotal(cartData))} Ft</div>
            </div>
            <div class="kiszallitasdiv">
              <div class="kiszallitastext">Kiszállítás</div>
              <div class="kiszallitasar">{Intl.NumberFormat('fr-FR').format(szallitas)} Ft</div>
            </div>
            <div class="kosarcsik"></div>
            <div class="teljesosszegdiv">
              <div class="osszesentext">Összesen</div>
              <div class="teljesar">{Intl.NumberFormat('fr-FR').format(szallitas+calculateTotal(cartData))} Ft</div>
            </div>
            <div class="bruttodiv">
              <div class="bruttotext">(Bruttó ár)</div>
            </div>
            <div class="fizetesgombholder">
              <a onClick={()=>payButton(szallitas+calculateTotal(cartData))} class="button-6 w-button">Fizetés</a>
            </div>
          </div>
        </div>
        <div class="buttonholder">
          <a class="bongeszesgomb w-button" onClick={()=>selectPage("Termek")}>Tovább böngészek! </a>
        </div>
      </div>
    </div>
    );
};
export default KosarTermek;