import React, { createContext, useState,useEffect, useContext } from 'react';

const TermekContext = createContext(null);

export const useTermek = () => useContext(TermekContext);

export const TermekProvider = ({ children }) => {
    const [data, setData] = useState({});
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [searchParams, setSearchParams] = useState({
        search: '',
        szel: 0,
        mag: 0,
        atm: 0,
        minP: 0,
        maxP: 0,
        szez: 0,
        mark: '',
        typefilter: 0,
        filterdesc: false,
        pagenum: 1,
        pagesize: 60
    });
    const [totalPages, setTotalPages] = useState(0);

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible); // Toggle the current state
    };

    const setParams = (Params) => {
        setSearchParams(Params);
    }

    // Fetch data length to calculate total pages
    const fetchDataLength = async () => {
        const response = await fetch(`http://95.138.193.55:41809/productLength?${new URLSearchParams(searchParams).toString()}`);
        const dataLength = await response.json();
        setTotalPages(Math.ceil(dataLength / searchParams.pagesize));
    };

    // Fetch data based on current search parameters
    const fetchData = async () => {
        const response = await fetch(`http://95.138.193.55:41809/product?${new URLSearchParams(searchParams).toString()}`);
        const result = await response.json();
        setData(result);
        await fetchDataLength();  // Ensure total pages is updated
    };

    useEffect(() => {
        fetchData(); // Fetch data on initial mount and when searchParams change
        console.log("THE NEW DATA IS: ");
        console.log(data);
    }, [searchParams]);

    // Public API for updating search parameters
    const updateSearchParams = (newParams) => {
        setSearchParams(prev => ({ ...prev, ...newParams }));
    };

    

    // Context value that will be passed to consumers
    const contextValue = {
        data,
        totalPages,isDropdownVisible,toggleDropdown,
        setParams,searchParams,
        updateSearchParams,
        currentPage: searchParams.page,
        setPage: (page) => updateSearchParams({ page }),
        setPageSize: (pagesize) => updateSearchParams({ pagesize })
    };

    return <TermekContext.Provider value={contextValue}>{children}</TermekContext.Provider>;
};