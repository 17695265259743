import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import MainSearchBottom from './MainSearchBottom';
import MainSuggestedProductsTitle from './MainSuggestedProductsTitle';
import MainSuggestedProducts from './MainSuggestedProducts';
import MainAboutUs from './MainAboutUs';
import MainTopBrands from './MainTopBrands';
import MainWhyUs from './MainWhyUs';
import MainContactUs from './MainContactUs';
import MainProfilePopup from './MainProfilePopup';
import PayPopup from '../cart/PayPopup';

const MainMainContainer = () => {

    return (
        <div className="maincont">
      <div id="card-container" className="maincardcontainer">
        <MainProfilePopup/>
        <MainSearchBottom/>
        <MainSuggestedProductsTitle/>
        <MainSuggestedProducts/>
        <MainAboutUs/>
        <MainTopBrands/>
        <MainWhyUs/>
        <MainContactUs/>
        <div></div>
      </div>
    </div>
    );
};
export default MainMainContainer;