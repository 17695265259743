import React, { useState, useEffect } from 'react';
import './../css/MainAccountPopup.css';
import { useAuth } from '../mainpagecomp/AuthContext';

const TermekAccountPopup = ({ isLoggedIn }) => {
    const {setError,fetchEmail,email, setEmail, handleRegister,sessionID,setSessionID,setLogged,logged, view, username, setUsername, password, setPassword, error, handleLogin, logout, setView,isPopupVisible,togglePopup} = useAuth();

    useEffect(() => {
        
          const handleBeforeUnload = (event) => {
            console.log("LOGOUT USER "+sessionID)
            const url = `http://95.138.193.55:41809/logout?sessionId=${sessionID}`;
            const data = new Blob([JSON.stringify({ sessionId: sessionID })], { type: 'application/json' });
            navigator.sendBeacon(url, data);
          };
    
        // Adding event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Cleanup function to remove event listener
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      const LogoutClicked = (event) => {
        console.log("LOGOUT USER "+sessionID)
        togglePopup();
        setView('default');
        setLogged(false);
        const url = `http://95.138.193.55:41809/logout?sessionId=${sessionID}`;
        const data = new Blob([JSON.stringify({ sessionId: sessionID })], { type: 'application/json' });
        navigator.sendBeacon(url, data);
      };
      const Cancel = () => {
        setView('default');
        setError('');
      }

    return (
        <div className="login-popup">
            {view === 'profile' && logged && (
                <div className="popup-content">
                    <h2>{username}</h2>
                    <div>{email || "noeamil"}</div>
                    <button onClick={() => LogoutClicked() }>Logout</button>
                </div>
            )}
            {view === 'default' && !logged && (
                <div className="popup-content">
                    <button onClick={() => setView('login')}>Login</button>
                    <button onClick={() => setView('register')}>Register</button>
                </div>
            )}
            {view === 'login' && (
                <div className="form-content">
                <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                    <button onClick={handleLogin}>Login</button>
                    <button onClick={() => Cancel()}>Cancel</button>
                    {error && <p className="error">{error}</p>}
                </div>
            )}
            {view === 'register' && (
                <div className="form-content">
                    <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
                    <input type="email" placeholder="Email"  value={email} onChange={e => setEmail(e.target.value)}/>
                    <input type="password" placeholder="Password"  value={password} onChange={e => setPassword(e.target.value)}/>
                    <button onClick={handleRegister}>Register</button>
                    <button onClick={() => Cancel()}>Cancel</button>
                    {error && <p className="error">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default TermekAccountPopup;