import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import TermekHeadBar from '../termekpagecomp/TermekHeadBar';
import TerminTireInfo from '../termkemintacomp/TerminTireInfo';
import TerminMoreInfo from '../termkemintacomp/TerminMoreInfo';
import TerminGyik from '../termkemintacomp/TerminGyik';
import TermekContactUs from '../termekpagecomp/TermekContactUs';
import NavbarKosar from '../NavbarKosar';


const TermekPage = () => {
  

  return (
    <div class="page-wrapper">
        <NavbarKosar/>
        <TermekHeadBar/>
        <TerminTireInfo/>
        <TerminMoreInfo/>
        <TerminGyik/>
        <TermekContactUs/>
    </div>  
  );
};
export default TermekPage;