import React from 'react';
import './../css/webflow.css';
import './../css/beni2.css';
import './../css/normalize.css';
import { useTermin } from '../termkemintacomp/TerminContext';

const ResponsePopup = () => {
    const {toggleRp,payColor,payText,setPayDetalis} = useTermin();
    return (
        <div style={{opacity:'1'}} class="profil-popup-wrapper">
      <div class="popup-container">
        <div class="popupelement popupmodal" style={{marginTop:"170px"}} >
          <div class="ms2-modal-row2" style={{height: "200px"}}>
            <div class="ms2-modal-nav2" style={{height: "200px", display:"flex",flexDirection:"column" ,alignItems:"center" }}>
            <button onClick={toggleRp} className="close-button" style={{width: "20px",marginLeft:'5px',backgroundColor:"white",borderRadius:"10px",alignSelf:"flex-start"}} >×</button>
            <h2 style={{color:payColor,marginTop:"60px"}}>{payText}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default ResponsePopup;