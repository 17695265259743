import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import marker from './../assets/images/marker.png';

const center = [46.54781404611556, 17.116884097269796];
const customIcon = L.icon({
  iconUrl: marker,
  iconSize: [20, 35], // Size of the icon
  iconAnchor: [17, 35], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -35]  // Point from which the popup should open relative to the iconAnchor
});
function MapComponent() {
  return (
    <MapContainer center={center} zoom={13} style={{ height: '400px', width: '40vw' }}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={center} icon={customIcon}>
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
  </MapContainer>
  )
}

export default React.memo(MapComponent);