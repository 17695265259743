import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import largeImage1 from '../../assets/images/logocarousel.jpg'; // Your large image path
import largeImage2 from '../../assets/images/logocarousel.jpg'; 

const MainTopBrands = () => {

    return (
        <div>
            <div className="div-block-34">
          <div className="div-block">
            <div className="div-block-25-cy">
              <div className="napszmar">Népszerű márkáink</div>
            </div>
            <div className="div-bck-26-copy">
              <div className="div-bloc-copy-copy"></div>
            </div>
          </div>
        </div>
        <div className="div-block-35">
          <div className="div-block-43">
            <div id="carouselmark" className="carouselmark">
                <img src={largeImage1} alt="Brand Image 1" className="carousel-image" />
                  <img src={largeImage2} alt="Brand Image 2" className="carousel-image" />
            </div>
          </div>
        </div>
        </div>
    );
};
export default MainTopBrands;