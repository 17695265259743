import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import { useTermin } from './TerminContext';

const TerminMoreInfo = () => {
    const {data} = useTermin();

    const getSeason = (szezon) => {
      if (szezon === 1) {return "Téli"}
      else if (szezon === 2) {return "Nyári"}
      else if (szezon === 3) {return "Nyégyévszakos"}
    }
    return (
        <div class="bovebbinfowrap">
      <div class="bovebbinfocontainer">
        <h1 class="technikaiinfo">Technikai információk</h1>
        <div class="specifikaciokjobbbal w-row">
          <div class="jobb-column w-col w-col-6">
            <div class="w-layout-grid specifikaciok">
              <div id="w-node-_861192c9-6cf8-9684-8897-f0ad7fa8b20e-78f34e8b" class="gyartodiv">
                <div class="gyartotext">Gyártó</div>
                <div class="gyartomegnevezes">{data.gyartoMegnevezes}</div>
              </div>
              <div id="w-node-_3650ce8c-c607-c520-d3aa-25acdcda043d-78f34e8b" class="mintadiv">
                <div class="mintatext">Minta</div>
                <div class="mintaminta">{data.mintazat}</div>
              </div>
              <div id="w-node-_45659a4c-281c-3e86-9bcf-201e822bf91d-78f34e8b" class="meretdiv">
                <div class="merettext">Méret</div>
                <div class="meret">{data.szelesseg} | {data.magassag} | {data.atmero}</div>
              </div>
              <div id="w-node-_0fde85de-6982-5897-6d3d-816c2ada6027-78f34e8b" class="szezondiv">
                <div class="szezontext">Szezon</div>
                <div class="szezon">{getSeason(data.szezon)}</div>
              </div>
              <div id="w-node-_68490e26-8852-2170-5179-8a3d2087d18b-78f34e8b" class="kategoriadiv">
                <div class="kategoriatext">Kategória</div>
                <div class="kategoria">{data.kateg}</div>
              </div>
              <div id="w-node-f63be97e-f32d-b3d1-2de8-f99ac3e4747d-78f34e8b" class="deffektturodiv">
                <div class="deffektturotexta">Deffekttűrő</div>
                <div class="deffektturo">{data.defekt ? "Igen" : "Nem"}</div>
              </div>
              <div id="w-node-_04e768f3-9a2f-1c18-bdb4-da6ef3ac1434-78f34e8b" class="peremvedelemdiv">
                <div class="peremvedelemtext">Peremvédelem</div>
                <div class="peremvedelem">{data.perem}</div>
              </div>
              <div id="w-node-_7531de53-593f-5a72-a77f-aa5c4e495789-78f34e8b" class="erositettoldalfaldiv">
                <div class="erositettoldalfaltext">Erősített oldalfal</div>
                <div class="erositettoldalfal">{data.erold ? "Igen" : "Nem"}</div>
              </div>
              <div id="w-node-d2c0db00-4484-57ef-7cbc-6a50ca158078-78f34e8b" class="indexdiv">
                <div class="indextext">Index besorolás</div>
                <div class="index">{data.index}</div>
              </div>
              <div id="w-node-_490a2344-dd2f-bd8c-5f8c-55fa1d854980-78f34e8b" class="fogyasztasdiv">
                <div class="fogyasztastext">Üzemanyag fogyasztás</div>
                <div class="fogyasztas">{data.fogyasztas}</div>
              </div>
              <div id="w-node-_291f3f32-db68-35f4-a84e-5f1259f57170-78f34e8b" class="tapadasdiv">
                <div class="tapadastext">Nedves tapadás</div>
                <div class="tapadas">{data.fekezes}</div>
              </div>
              <div id="w-node-_302106c6-0bb0-5ee8-db95-cfcff089128f-78f34e8b" class="zajdiv">
                <div class="gordulesizajtext">Gördülési zaj</div>
                <div class="gordulesizaj">{data.gordZaj}</div>
              </div>
              <div id="w-node-_6ecc131f-daaa-9501-edb2-baa5b1619303-78f34e8b" class="cikkszamdiv">
                <div class="cikkszamtext">Gyártói cikkszám</div>
                <div class="cikkszam">{data.cikkszam}</div>
              </div>
            </div>
          </div>
          <div class="leiras w-col w-col-6">
            <p class="leirasszoveg1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
            <p class="leirasszoveg2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
          </div>
        </div>
      </div>
    </div>
    );
};
export default TerminMoreInfo;