import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/paypopup.css';
import './../css/normalize.css';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { useTermin } from '../termkemintacomp/TerminContext';


const stripePromise = loadStripe('pk_live_51OpUvdAU5rE1Ep1wurdfY2mhAmlAaqNRtVbqKvsvLGUiXADjf2i0j1U10fhZxmtvyhnxapv3Rm4MOWggldkPtbB800wlrxM33T');

const PayPopup = () => {
    const {openedPay,togglePay,payPrice} = useTermin();

    return (
        <div style={{opacity:1}} className="profil-popup-wrapper">
      <div className="popup-container" style={{marginBottom:'250px'}}>
        <div className="popupelement popupmodal">
          <div className="ms2-modal-row" >
            <div className="ms2-modal-na">
            <button onClick={togglePay} className="close-button" >×</button>
            <div className='stripeholder'>
            <Elements stripe={stripePromise}>
                <CheckoutForm amount={/*payPrice*/200} />
              </Elements>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default PayPopup;