import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import { useAuth } from './AuthContext';

const MainAboutUs = () => {
    const {selectPage} = useAuth();
    return (
        <div className="rolunkblock" id='rolunkblock'>
          <div className="div-block-9">
            <div className="div-block-25">
              <div className="text-block-13">Rólunk</div>
            </div>
            <div className="div-block-26">
              <div className="dasdasd"></div>
            </div>
          </div>
          <div className="div-block-29">
            <div className="div-block-30">
              <div className="text-block-15">A Pannon Tires Kft. alapításakor egy olyan cég létrehozása<br/>volt a cél, amely a megbízói<br/>igényeket teljeskörűen kiszolgálja. Magán- és üzleti <br/>ügyfeleinek a következő<br/>termékpalettát kínáljuk: gumiabroncsok személygépkocsira, <br/>motorkerékpárra és<br/>tehergépkocsira, buszokra. A vásárló elégedettsége a mi <br/>örömünk, ezért hangsúlyt<br/>fektetünk a gyors, precíz kiszolgálásra. Fontosnak tarjuk, <br/>hogy felmerülő kérdéseire<br/>választ tudjunk adni, akár már a megrendelési folyamat <br/>során, ezért bármely<br/>elérhetőségünkön feltett kérdésére 1 órán belül <br/>válaszolunk.<br/>Minden általunk forgalmazott termékre garanciát vállalunk, <br/>elérhetőek vagyunk és<br/>segítünk a megoldásban.</div>
              <a onClick={()=>selectPage("Termek")} className="button w-button">Vásárlás most!</a>
            </div>
            <div className="div-block-31"><img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imagesKépernyőkép-2024-04-20-001145.png" loading="lazy" sizes="35vw" srcset="images/Képernyőkép-2024-04-20-001145-p-500.png 500w, images/Képernyőkép-2024-04-20-001145.png 643w" alt="" className="image-36"/></div>
          </div>
        </div>
    );
};
export default MainAboutUs;