import React, { useState, useEffect, useRef } from 'react';
import './../css/MainAccountPopup.css';
import { useAuth } from './AuthContext';
import { useCart } from '../cart/CartContext';

const MainAccountPopup = ({ isLoggedIn }) => {
    const {setError,fetchEmail,email, setEmail, handleRegister,sessionID,setSessionID,setLogged,logged, view, username, setUsername, password, setPassword, error, handleLogin, logout, setView,isPopupVisible,togglePopup} = useAuth();
    const popupRef = useRef(null);
    
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
          togglePopup(); // Close the popup if the click is outside
      }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const Login = () => {
      console.log("new sessionid :"+sessionID);
      handleLogin();
    }

    useEffect(() => {
        
          const handleBeforeUnload = (event) => {
            console.log("LOGOUT USER "+sessionID)
            const url = `http://95.138.193.55:41809/logout?sessionId=${sessionID}`;
            const data = new Blob([JSON.stringify({ sessionId: sessionID })], { type: 'application/json' });
            navigator.sendBeacon(url, data);
          };
    
        // Adding event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Cleanup function to remove event listener
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      const LogoutClicked = (event) => {
        console.log("LOGOUT USER "+sessionID)
        togglePopup();
        setView('default');
        setLogged(false);
        const url = `http://95.138.193.55:41809/logout?sessionId=${sessionID}`;
        const data = new Blob([JSON.stringify({ sessionId: sessionID })], { type: 'application/json' });
        navigator.sendBeacon(url, data);
      };
      const Cancel = () => {
        setView('default');
        setError('');
      }

    return (
        <div ref={popupRef} className="login-popup">
            {view === 'profile' && logged && (
                <div className="popup-content" style={{width :"auto"}}>
                    <div style={{fontSize : '25px' , fontWeight : '700' , marginBottom : '10px', marginTop : '10px'}}>Üdvözlünk,</div>
                    <div style={{fontSize : '25px' , fontWeight : '700' , marginBottom : '10px'}}>{username}!</div>
                    <div>{email || "noeamil"}</div>
                    <button onClick={() => LogoutClicked() }>Logout</button>
                </div>
            )}
            {view === 'default' && !logged && (
                <div className="popup-content">
                    <button onClick={() => setView('login')}>Login</button>
                    <button onClick={() => setView('register')}>Register</button>
                </div>
            )}
            {view === 'login' && (
                <div className="form-content">
                <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                    <button onClick={handleLogin}>Login</button>
                    <button onClick={() => Cancel()}>Cancel</button>
                    {error && <p className="error">{error}</p>}
                </div>
            )}
            {view === 'register' && (
                <div className="form-content">
                    <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
                    <input type="email" placeholder="Email"  value={email} onChange={e => setEmail(e.target.value)}/>
                    <input type="password" placeholder="Password"  value={password} onChange={e => setPassword(e.target.value)}/>
                    <button onClick={handleRegister}>Register</button>
                    <button onClick={() => Cancel()}>Cancel</button>
                    {error && <p className="error">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default MainAccountPopup;