import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import ver from '../../assets/images/th.jpg'

const TerminGyik = () => {

    return (
        <div id="gyakran-ismetelt-kerdesek" class="europacimke">
        <div class="cimkecontainer flex d-vertical gap-v-80 tab-gap-v-60 mob-gap-v-32">
          <div class="accordion-wrapper">
            <div class="accordion-wrap slide-in-btm">
              <div class="europailine"></div>
              <div class="accordion-trigger flex j-space-between a-center">
                <h3 class="tab-max-w-600">Európai abroncscímke</h3>
                <div class="accordion-indicator"><img src={ver} loading="lazy" id="cimkekep" alt="" class="cimkekep"/>
                  <div class="html-embed-2 w-embed w-script">https://www.iconsdb.com/icons/preview/black/arrow-24-xxl.png
                  </div>
                </div>
              </div>
              <div data-w-id="7a030501-9f0a-af57-736a-13914c75c360" class="europailine"></div>
              <div class="overflow-hidden">
                <div class="accordion-body">
                  <div class="max-80ch tab-max-w-600">
                    <div class="p-text1 opacity80"><span class="text-span-2">Nedves tapadás</span><br/><br/>A kiváló nedves tapadású abroncsok (A vagy B besorolás) féktávolsága nedves úton rövidebb, ezért ezek biztonságosabbak esőben. Az üzemanyag-hatékonysági mutatók besorolásához hasonlóan a D besorolást nem alkalmazzák, a G besorolás pedig már csak elvétve, régebbi mintázatok esetén használt.<br/><br/><span class="text-span">Üzemanyag-fogyasztás</span><br/><br/>A gumiabroncsok a jármű fogyasztásának közel 20 %-ért felelősek, így ha Ön több kilométert szeretne megtenni egy tank üzemanyag felhasználásával, üzemanyag hatékonyság szempontjából magasabb besorolású abroncsot kell választania. Ezzel a szén-dioxid kibocsátásának csökkentéséhez is hozzájárul. Mindez a gördülési ellenállásra is hatással van, hiszen az abroncs mozgás során energiát ad le. Minél kisebb a gördülési ellenállás, annál kevesebb energia vész el és annál alacsonyabb lesz az üzemanyag fogyasztás, valamint a széndioxid kibocsátás. Egy A és egy G besorolású abroncs között a kb. 1000 km-en a fogyasztásban megmutatkozó különbség akár 6 liter is lehet.<br/><br/><span class="text-span-3">Zajszint</span><br/><br/>Az abroncs által generált külső zaj, amelyet decibelben mérnek. A címkén találkozhatunk konkrét zaj értékkel (pl. 71dB) és zajszinttel is (pl. 2-es szint). Az abroncs címkén található ikon kitöltöttsége magasabb zajszintet jelent.</div>
                  </div>
                </div>
              </div>
              <div class="europailine"></div>
            </div>
          </div>
        </div>
      </div>
    );
};
export default TerminGyik;