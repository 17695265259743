import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import TermekHeadBar from '../termekpagecomp/TermekHeadBar';
import TermekContactUs from '../termekpagecomp/TermekContactUs';
import KosarPopup from '../kosarpagecomp/KosarPopup';
import KosarEmptyWrap from '../kosarpagecomp/KosarEmptyWrap';
import KosarTermek from '../kosarpagecomp/KosarTermek';
import NavbarKosar from '../NavbarKosar';
import { useTermin } from '../termkemintacomp/TerminContext';
import PayPopup from '../cart/PayPopup';
import ResponsePopup from '../cart/ResponsePopup';


const KosarPage = () => {
  const {openedPay,togglePay,rpopen} = useTermin();

  return (
    <div class="pagewrapper">
        {rpopen && <ResponsePopup/>}
        {openedPay && <PayPopup/>}
        <NavbarKosar/>
        <TermekHeadBar/>
        <KosarEmptyWrap/>
        <KosarTermek/>
        <TermekContactUs/>
    </div>
  );
};
export default KosarPage;