import React, {useRef,useEffect} from 'react';
import './css/webflow.css';
import './css/navbar.css'
import './css/basket.css';
import './css/normalize.css';
import { useAuth } from './mainpagecomp/AuthContext';
import CartItem from './cart/CartItem';
import { useCart } from './cart/CartContext';
import foursesImg from './../assets/images/fourseasgrey.png';
import winterImg from './../assets/images/wintergrey.png';
import summerImg from './../assets/images/summergrey.png';
import gaspumpImg from './../assets/images/gaspump.png';
import noimageImg from './../assets/images/noimage.png';
import rainImgsrc from './../assets/images/rain.png';
import volumeImg from './../assets/images/volume.png';

const NavbarKosar = () => {
    const {isNavOpen,changeNav,selectPage,logged,closeNav} = useAuth();
    const {cartData,calculateTotal,removeBasketData} = useCart();
    const popupRef = useRef(null);
    
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
          closeNav(); // Close the popup if the click is outside
      }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const kosarClick = () => {
        selectPage('Kosar');
        changeNav();
    }

    const getImageUrl = (item) => {
      return item.imgurl === "N/A" ? noimageImg : item.imgurl;
  };

  // Function to get season image
  const getSeasonImg = (season) => {
      switch (season) {
          case 1:
              return winterImg;
          case 2:
              return summerImg;
          default:
              return foursesImg;
      }
  };

  // Function to get season text
  const getSeason = (season) => {
      switch (season) {
          case 1:
              return "Téli";
          case 2:
              return "Nyári";
          default:
              return "Négyévszakos";
      }
  };

  const removeItem = (index) => {
    removeBasketData(index)
  }

    return (
        <div ref={popupRef} className={`navbar ${isNavOpen ? 'open' : ''}`}>
      <button className="close-btn advancedfilterbutt3" onClick={changeNav}>Bezárás</button>
      <div className="content">
        <div className="data-section">
        {logged ? (
            cartData.products && cartData.products.length > 0 ? (
              cartData.products.map((product, index) => (
                <div className="basket-item">
                  <div className="basket-left">
                    <div className="card-image-container3">
                      <img className="card-image3" src={getImageUrl(product)} alt="product" onError={(e) => e.target.style.display = 'none'} />
                    </div>
                    <hr className="divider" />
                  </div>
                  <div className="basket-right">
                    <button className="remove-button" onClick={() => removeItem(product.cikkszam)}>X</button>
                    <div>Név: {product.mintazat}</div>
                    <div>Mennyiség: {cartData.amounts[index]}</div>
                    <div>Ár: {product.formazottAr} Ft</div>
                    <div>Total: {Intl.NumberFormat('de-DE').format(product.eladasiar * cartData.amounts[index])} Ft</div>
                  </div>
                </div>
              ))
            ) : (
              <div>No products found</div>
            )
          ) : (
            <div>Login First</div>
          )}
        </div>
        <div>
          <div style={{display:'flex',flexDirection:'row'}}>
            <div style={{fontSize :'26px',fontWeight:'700',marginLeft:'20px'}}>Teljes Ár:</div>
            <div style={{fontSize :'26px',fontWeight:'700',marginLeft:'10px', color:'#bb0808'}}>{Intl.NumberFormat('fr-FR').format(calculateTotal(cartData))} Ft</div>
          </div>
        </div>
        <div onClick={kosarClick} className="carttwo-button advancedfilterbutt3">Kosár</div>
      </div>
    </div>
    );
};
export default NavbarKosar;