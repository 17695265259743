import React,{useEffect,useState} from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import { useTermek } from './TermekContext';
import TermekCardContainer from './TermekCardContainer';

const TermekList = () => {
  const {data,totalPages,updateSearchParams,currentPage,setPage,setPageSize} = useTermek();
  const [options, setOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const handleSelectChange = (e) => {
    setSelectedPage(parseInt(e.target.value, 10)); // Update the selectedPage state
  };

  const nextButton = () => {
    if (selectedPage<totalPages) {setSelectedPage(selectedPage+1)}
  }

  const prewButton = () => {
    if (selectedPage>1) {setSelectedPage(selectedPage-1)}
  }

  useEffect(() => { updateSearchParams({ pagenum: parseInt(selectedPage) });// Fetch data on initial mount and when searchParams change
  }, [selectedPage]);

  useEffect(() => { // Fetch data on initial mount and when searchParams change
  }, [data]);

  useEffect(() => {
    // Create an array from 1 to numOptions
    const newOptions = Array.from({ length: totalPages }, (_, i) => i + 1);
    setOptions(newOptions);
  }, [totalPages]);
    return (
        <div >
        <div class="w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" class="form-4" data-wf-page-id="6621507627b91737ed0b2675" data-wf-element-id="15e4c8d8-c8ca-c4ab-ef35-ee6ea17a0fa4">
              <div class="div-block-333">
                <a id="prevbutt2" onClick={prewButton}  class="prewbuttnew w-button">Előző</a>
                <select
                  id="pageselect2"
                  name="field"
                  className="pageselect2 w-select"
                  style={{ zIndex: '10' }}
                  value={selectedPage} // Controlled component
                  onChange={handleSelectChange} // Handle changes
                >
                  {options.map(option => (
                      <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <a id="nextbutt2" onClick={nextButton} class="nextbuttnew w-button">Következő</a>
                <select id="pagesizesel" name="field" data-name="Field" class="pagesizesel w-select">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </select>
              </div>
            </form>
          </div>
            <div class="div-block-13" style={{marginTop : "30px",marginBottom : "30px"}}>
          <div class="div-block-9">
            {/*<div class="div-block-27">
              <div class="text-block-14">Termékek</div>
            </div>*/}
            <div class="div-block-11">
              <div class="div-block-10"></div>
            </div>
          </div>
        </div>
        <TermekCardContainer items={data}/>
        {/*<div id="Allcardcontainer" class="allcardcontainer"></div>*/}
          <div class="w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" class="form-4" data-wf-page-id="6621507627b91737ed0b2675" data-wf-element-id="15e4c8d8-c8ca-c4ab-ef35-ee6ea17a0fa4">
              <div class="div-block-3-copy">
                <a id="prevbutt2" onClick={prewButton} class="prewbuttnew w-button">Előző</a>
                <select
                  id="pageselect2"
                  name="field"
                  className="pageselect2 w-select"
                  style={{ zIndex: '10' }}
                  value={selectedPage} // Controlled component
                  onChange={handleSelectChange} // Handle changes
                >
                  {options.map(option => (
                      <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <a id="nextbutt2" onClick={nextButton} class="nextbuttnew w-button">Következő</a>
                <select id="pagesizesel" name="field" data-name="Field" class="pagesizesel w-select">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </select>
              </div>
            </form>
          </div>
        </div>
    );
};
export default TermekList;