import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import TermekHeadBar from '../termekpagecomp/TermekHeadBar';
//import TermekProfilePopup from '../mainpagecomp/TermekProfilePopup';
import TermekMainContainer from '../termekpagecomp/TermekMainContainer';
import NavbarKosar from '../NavbarKosar';


const TermekPage = () => {
  

  return (
    <div id="SiteContainer" class="sitecontainer">
        <NavbarKosar/>
        <TermekHeadBar/>
        <div class="div-block-22">
            <div class="text-block-16">Termékeink</div>
        </div>
        <TermekMainContainer/>
    </div>  
  );
};
export default TermekPage;