import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from '../mainpagecomp/AuthContext';

const CartContext = createContext(null);

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartData,setCartData] = useState([]);
    const {sessionID,isNavOpen} = useAuth();
    const [szallitas,setSzallitas] = useState(0);

    function calculateTotal(cartData) {
        // Ensure there is data to process
        if (!cartData || !cartData.products || !cartData.amounts) {
          return 0;  // Return 0 if data is missing or incomplete
        }
      
        // Calculate the total cost of all products in the cart
        let total = 0;
        cartData.products.forEach((product, index) => {
          const amount = cartData.amounts[index] || 0;  // Default to 0 if no amount specified
          total += product.eladasiar * amount;
        });

        if (total < 5000) {setSzallitas(0)}
        else if (total < 100000) {setSzallitas(3500)}
        else if (total < 500000) {setSzallitas(5000)}
        else if (total < 1000000) {setSzallitas(10000)}
        else {setSzallitas(20000)}
        console.log("Szallitas = "+szallitas+"  Total = "+total);
      
        return total;
      }

    const getBasketData = async () => {
        if (sessionID !=-1) {
        const response = await fetch(`http://95.138.193.55:41809/getBasket?uniqueId=${sessionID}`);
        const result = await response.json();
        setCartData(result);
        console.log(result);
        console.log(cartData);
        calculateTotal(cartData);
        }
    };
    
    const addBasketData = async (cikk,am) => {
        if (sessionID !=-1) {
            const response = await fetch(`http://95.138.193.55:41809/addBasketItem?cikkszam=${cikk}&amount=${am}&uniqueId=${sessionID}`);
            getBasketData();
            }
    }

    const removeBasketData = async (cikk) => {
        if (sessionID !=-1) {
            const response = await fetch(`http://95.138.193.55:41809/deleteBasketItem?cikkszam=${cikk}&uniqueId=${sessionID}`);
            getBasketData();
            }
    }

    const minusBasketData = async (cikk,am) => {
        if (sessionID !=-1) {
            const response = await fetch(`http://95.138.193.55:41809/removeBasketItem?cikkszam=${cikk}&amount=${am}&uniqueId=${sessionID}`);
            getBasketData();
            }
    }

    useEffect(() => {
        getBasketData();
    }, [sessionID,isNavOpen]);

    const contextValue = {
        cartData,removeBasketData,addBasketData,calculateTotal,minusBasketData,szallitas
    };

    return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};