import React, {useState} from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'

const MainWhyUs = () => {
    // State to track the currently active tab
    const [activeTab, setActiveTab] = useState('tab1');

    // Function to change the active tab
    const selectTab = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className="miertapannon">
          <div className="miertapannonheroheading">
            <div className="miertapannonhead">
              <div className="textasd">Miért a Pannon Tires?</div>
            </div>
            <div className="miertapannoncsik">
              <div className="sad"></div>
            </div>
          </div>
          <div className="tabmiertapannon">
            <div className="div-block-40">
                <div id="tabbutt1" className="g1" onClick={() => selectTab('tab1')}>
                    <img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imageskapocswhite.png" loading="lazy" sizes="(max-width: 479px) 8vw, 34px" srcset="images/kapocswhite-p-500.png 500w, images/kapocswhite-p-800.png 800w, images/kapocswhite-p-1080.png 1080w, images/kapocswhite-p-1600.png 1600w, images/kapocswhite.png 1890w" alt="" className="image-5"/>
                </div>
                <div id="tabbutt2" className="g2" onClick={() => selectTab('tab2')}>
                    <img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imagesverified.png" loading="lazy" sizes="(max-width: 479px) 8vw, 34px" srcset="images/verified-p-500.png 500w, images/verified-p-800.png 800w, images/verified-p-1080.png 1080w, images/verified-p-1600.png 1600w, images/verified.png 1890w" alt="" className="image-5"/>
                </div>
                <div id="tabbutt3" className="g3" onClick={() => selectTab('tab3')}>
                <img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imagesdollar.png" loading="lazy" sizes="(max-width: 479px) 8vw, 34px" srcset="images/dollar-p-500.png 500w, images/dollar-p-800.png 800w, images/dollar-p-1080.png 1080w, images/dollar-p-1600.png 1600w, images/dollar.png 1890w" alt="" className="image-5"/>
                </div>
              </div>
            <div id="tab1" style={{ display: activeTab === 'tab1' ? 'block' : 'none' }} className="tabcontentwrap"><img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imageskapocsgrey.png" loading="lazy" sizes="(max-width: 479px) 69vw, 200px" srcset="images/kapocsgrey-p-500.png 500w, images/kapocsgrey-p-800.png 800w, images/kapocsgrey-p-1080.png 1080w, images/kapocsgrey-p-1600.png 1600w, images/kapocsgrey.png 1890w" alt="" className="image-6"/>
              <div className="div-block-41">
                <div className="text-block-17">•<strong>MERT</strong> csak olyan terméket és szolgáltatást forgalmazunk, <br/>amit mi is szívesen megvennénk.</div>
              </div>
              <div className="dsada">
                <div className="text-block-17">•<strong>MERT</strong> valljuk, hogy egy terméket a minőségén és a tudásán túl a <br/>hozzáadott érték és szolgáltatás tesz versenyképessé, nem pedig <br/>csak az ára.</div>
              </div>
              <div className="dsad">
                <div className="text-block-17"><strong>•MERT</strong> kíváncsiak vagyunk a véleményedre akkor is, ha <br/>az nem egyezik a miénkkel.</div>
              </div>
            </div>
            <div id="tab2" style={{ display: activeTab === 'tab2' ? 'block' : 'none' }} className="dvi38">
              <div className="dasdada">
                <div className="text-block-17">• <strong>MERT</strong> hiszünk azokban a termékekben, amiket forgalmazunk.</div>
              </div>
              <div className="dasdasf">
                <div className="text-block-17"><strong>• MERT</strong> ha hibázunk, nem félünk bevallani, és feltétlenül javítjuk.</div>
              </div>
              <div className="fasfa">
                <div className="text-block-17"><strong>• MERT</strong> közvetlen kapcsolatra törekszünk Veled akkor is, <br/>ha nálunk csak érdeklődsz, devalamiért máshol vagy mást vásárolsz.</div>
              </div><img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imagesver30.png" loading="lazy" sizes="100vw" srcset="images/ver30-p-500.png 500w, images/ver30-p-800.png 800w, images/ver30-p-1080.png 1080w, images/ver30-p-1600.png 1600w, images/ver30.png 1890w" alt="" className="img23"/>
            </div>
            <div id="tab3" style={{ display: activeTab === 'tab3' ? 'block' : 'none'}} className="asdasda">
              <div className="asdafsfa">
                <div className="text-block-17">•<strong>MERT</strong> sohasem felejtjük el, hogy mi is állunk a &quot;pult&quot; másik  <br/>oldalán nap, mint nap.</div>
              </div>
              <div className="fasfasfa">
                <div className="text-block-17">•<strong>MERT</strong> akcióink alkalmával örömmel adunk valamilyen plusz <br/>szolgáltatást vagy plusz terméket neked.</div>
              </div>
              <div className="fasfagq">
                <div className="text-block-17">•<strong>MERT</strong> elérhetőek vagyunk akkor is, ha gond van a termékkel, és <br/>akkor is, ha éppen azörömöd vagy elégedettséged akarod velünk <br/>megosztani.</div>
              </div><img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imagesdoll30.png" loading="lazy" sizes="100vw" srcset="images/doll30-p-500.png 500w, images/doll30-p-800.png 800w, images/doll30-p-1080.png 1080w, images/doll30-p-1600.png 1600w, images/doll30.png 1890w" alt="" className="image42"/>
            </div>
          </div>
        </div>
    );
};
export default MainWhyUs;