import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import MainHeadBar from '../mainpagecomp/MainHeadBar';
import MainProfilePopup from '../mainpagecomp/MainProfilePopup';
import MainBackVid from '../mainpagecomp/MainBackVid';
import MainMainContainer from '../mainpagecomp/MainMainContainer';
import NavbarKosar from '../NavbarKosar';


const MainPage = (setPage) => {
  

  return (
    <div id="SiteContainer" className="sitecontainer">
    <NavbarKosar/>
    <MainHeadBar/>
    
    <MainBackVid/>
    <MainMainContainer/>    
  </div>
  );
};
export default MainPage;