import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/mainpagecomp/AuthContext';
import { TermekProvider } from './components/termekpagecomp/TermekContext';
import { SearchProvider } from './components/mainpagecomp/SearchCachecContext';
import { CartProvider } from './components/cart/CartContext';
import { TerminProvider } from './components/termkemintacomp/TerminContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <TermekProvider>
      <SearchProvider>
      <CartProvider>
      <TerminProvider>
        <App />
      </TerminProvider>
      </CartProvider>
      </SearchProvider>
      </TermekProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
