import React, { createContext, useState,useEffect, useContext } from 'react';

const SearchContext = createContext(null);

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [searchParamsMain, setSearchParamsMain] = useState({
        search: '',
        szel: 0,
        mag: 0,
        atm: 0,
        minP: 0,
        maxP: 0,
        szez: 0,
        mark: '',
        typefilter: 0,
        filterdesc: false,
        pagenum: 1,
        pagesize: 36
    });

    const [searchParamsTerm, setSearchParamsTerm] = useState({
        search: '',
        szel: 0,
        mag: 0,
        atm: 0,
        minP: 0,
        maxP: 0,
        szez: 0,
        mark: '',
        typefilter: 0,
        filterdesc: false,
        pagenum: 1,
        pagesize: 36
    });

    const startSearch = () => {
        setSearchParamsTerm(searchParamsMain);
    }

    // Public API for updating search parameters
    const updateSearchParamsMain = (newParams) => {
        setSearchParamsMain(prev => ({ ...prev, ...newParams }));
    };

    

    // Context value that will be passed to consumers
    const contextValue = {
        updateSearchParamsMain,
        searchParamsMain,searchParamsTerm,
        startSearch
    };

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};