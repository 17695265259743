import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import TermekHeadBar from '../termekpagecomp/TermekHeadBar';
import SuccesTermekWrap from '../succespagecomp/SuccesTermekWrap';
import TermekContactUs from '../termekpagecomp/TermekContactUs';
import NavbarKosar from '../NavbarKosar';

const SuccesPage = () => {

    return (
        <div>
            <NavbarKosar/>
            <TermekHeadBar/>
            <SuccesTermekWrap/>
            <TermekContactUs/>
        </div>
    );
};
export default SuccesPage;