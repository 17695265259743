import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import { useAuth } from './AuthContext';

const MainPage = () => {
    const {selectPage} = useAuth();
    return (
        <div className="div-block-5">
      <div data-poster-url="https://uploads-ssl.webflow.com/661ec6a72add5eaaf2812f47/66202d328cfbff91fed3e10b_Pannonsalalalalomhandbrake-poster-00001.jpg" data-video-urls="videos/Pannonsalalalalomhandbrake-transcode.mp4,videos/Pannonsalalalalomhandbrake-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video w-background-video w-background-video-atom">
      <video
                id="3389545e-ff7c-f66d-9a76-673b61ac046c-video"
                autoPlay
                loop
                muted
                playsInline
                data-object-fit="cover"
                className="backgroundVideo"
                poster="https://uploads-ssl.webflow.com/661ec6a72add5eaaf2812f47/66202d328cfbff91fed3e10b_Pannonsalalalalomhandbrake-poster-00001.jpg"
                data-wf-ignore="true"
            >
                <source src="/videos/Pannonsalalalalomhandbrake-transcode.mp4" type="video/mp4" />
                <source src="/videos/Pannonsalalalalomhandbrake-transcode.webm" type="video/webm" />
            </video>
        <div className="videotext">
          <div className="div-block-20"></div>
          <div className="text-block-11">Pannon Tires</div>
          <div className="text-block-12">Tapadj rá! - Pannon Tires, a vezetés </div>
          <div className="text-block-12">megbízható társa.</div>
          <a id="buynowbutt" onClick={()=>selectPage("Termek")} className="buynowbutt w-button">Vásárolj most!</a>
        </div>
      </div>
    </div>
    );
};
export default MainPage;