import React, { useState,useContext } from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import { useSearch } from './SearchCachecContext';
import { useTermek } from '../termekpagecomp/TermekContext';
import { useAuth } from './AuthContext';

const MainSearchBottom = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const {updateSearchParamsMain,searchParamsMain,searchParamsTerm,startSearch} = useSearch();
    const {setParams,toggleDropdown} = useTermek();
    const {selectPage} = useAuth();

    // Function to toggle dropdown visibility
    const toggleDropdown2 = () => {
        setDropdownVisible(!isDropdownVisible); // Toggle the current state
    };

    const handleChangeMag = (event) => {
      updateSearchParamsMain({mag : event.target.value});  // Update the state with the new value
    };
    const handleChangeSearch = (event) => {
      updateSearchParamsMain({search : event.target.value});  // Update the state with the new value
    };
    const handleChangeSzel = (event) => {
      updateSearchParamsMain({szel : event.target.value});  // Update the state with the new value
    };
    const handleChangeAtm = (event) => {
      updateSearchParamsMain({atm : event.target.value});  // Update the state with the new value
    };
    const handleChangeMark = (event) => {
      updateSearchParamsMain({mark : event.target.value});  // Update the state with the new value
    };
    const handleChangeMinP = (event) => {
      updateSearchParamsMain({minP : event.target.value});  // Update the state with the new value
    };
    const handleChangeMaxP = (event) => {
      updateSearchParamsMain({maxP : event.target.value});  // Update the state with the new value
    };
    const handleChangeSort = (event) => {
      updateSearchParamsMain({typefilter : event.target.value});  // Update the state with the new value
    };
    const handleChangeDesc = (event) => {
      updateSearchParamsMain({filterdesc : event.target.checked});  // Update the state with the new value
    };

    const searchButton = () => {
      selectPage("Termek");
      setParams(searchParamsMain);
      toggleDropdown();
    }

    return (
        <div>
        <div className="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-2" data-wf-page-id="6620f5731fb95a6b89fec1cd" data-wf-element-id="cea033da-a41a-3ef2-7c53-1016d4e615cf">
            <div className="div-block-6">
              <div className="div-block-7"></div>
            </div>
            <div id="searchtextdiv" className="searchtextdiv">
              <div className="text-block">Keressen a termékeink között:</div>
            </div>
            <div className="div-block-4">
                <input value={searchParamsMain.search}  onChange={handleChangeSearch} className="searchparam w-input" maxlength="256" name="email" data-name="Email" placeholder="Keresés" type="email" id="searchparam" required="" />
                <div className="div-block-8">
                  <a onClick={searchButton} id="advancedfilterbutt" className="advancedfilterbutt2">Keresés</a>
                </div>
            </div>
          </form>
        </div>
        <div className="div-block-12">
          <div className="form-block-2 w-form">
            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="form-3" data-wf-page-id="6620f5731fb95a6b89fec1cd" data-wf-element-id="7e77e6da-f0db-3dda-d53e-a91fb51ffc3b">
              <div id="advoptdropdown" className="advoptdropdown" style={{ maxHeight: isDropdownVisible ? '1000px' : '0px' }}>
                <div className="twoinput">
                  <div className="selholder">
                    <div className="text-block-3">Magasság:</div>
                    <select id="maginp" name="field" data-name="Field" className="maginp w-select" value={searchParamsMain.mag}  onChange={handleChangeMag} >
                      <option value="0">-</option>
                      <option value="85">85</option>
                      <option value="80">80</option>
                      <option value="75">75</option>
                      <option value="70">70</option>
                      <option value="65">65</option>
                      <option value="60">60</option>
                      <option value="55">55</option>
                      <option value="50">50</option>
                      <option value="45">45</option>
                      <option value="40">40</option>
                      <option value="35">35</option>
                      <option value="30">30</option>
                      <option value="25">25</option>
                    </select>
                  </div>
                  <div className="selholder2">
                    <div className="text-block-4">Szélesség:</div>
                    <select id="szelinp" name="field" data-name="Field" className="szelinp w-select" value={searchParamsMain.szel}  onChange={handleChangeSzel}>
                      <option value="0">-</option>
                      <option value="325">325</option>
                      <option value="315">315</option>
                      <option value="305">305</option>
                      <option value="295">295</option>
                      <option value="285">285</option>
                      <option value="275">275</option>
                      <option value="265">265</option>
                      <option value="255">255</option>
                      <option value="245">245</option>
                      <option value="235">235</option>
                      <option value="225">225</option>
                      <option value="215">215</option>
                      <option value="205">205</option>
                      <option value="195">195</option>
                      <option value="185">185</option>
                      <option value="180">180</option>
                      <option value="175">175</option>
                      <option value="165">165</option>
                      <option value="155">155</option>
                      <option value="145">145</option>
                      <option value="150">150</option>
                      <option value="160">160</option>
                      <option value="120">120</option>
                      <option value="110">110</option>
                      <option value="18">18</option>
                      <option value="16">16</option>
                    </select>
                  </div>
                </div>
                <div className="twoinput">
                  <div className="selholder">
                    <div className="text-block-5">Átmérő:</div>
                    <select id="atminp" name="field" data-name="Field" className="atminp w-select" value={searchParamsMain.atm}  onChange={handleChangeAtm}>
                      <option value="0">-</option>
                      <option value="23">23</option>
                      <option value="22">22</option>
                      <option value="21">21</option>
                      <option value="20">20</option>
                      <option value="19">19</option>
                      <option value="18">18</option>
                      <option value="17">17</option>
                      <option value="16">16</option>
                      <option value="15">15</option>
                      <option value="14">14</option>
                      <option value="13">13</option>
                      <option value="12">12</option>
                      <option value="10">10</option>
                      <option value="8">8</option>
                      <option value="7">7</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                  <div className="selholder2">
                    <div className="text-block-6">Márka:</div>
                    <select value={searchParamsMain.mark}  onChange={handleChangeMark} id="markselect" name="field" data-name="" required="" title="Márka" className="markselect w-select">
                      <option value="">Mind</option>
                      <option value="CON">CONTINENTAL</option>
                      <option value="UNI">UNIROYAL</option>
                      <option value="MIC">MICHELIN</option>
                      <option value="KOR">KORMORAN</option>
                      <option value="KIN">KINGSTAR</option>
                      <option value="HAN">HANKOOK</option>
                      <option value="FUL">FULDA</option>
                      <option value="PIR">PIRELLI</option>
                      <option value="MAT">MATADOR</option>
                      <option value="ROY">ROYAL BLACK</option>
                      <option value="APO">APOLLO</option>
                      <option value="LAU">LAUFENN</option>
                      <option value="BAR">BARUM</option>
                      <option value="BRI">BRIDGESTONE</option>
                      <option value="KLE">KLEBER</option>
                      <option value="BFG">BFGOODRICH</option>
                      <option value="DEL">DELI</option>
                      <option value="DUN">DUNLOP</option>
                      <option value="TRA">TRACMAX</option>
                      <option value="SEC">SECURITY</option>
                      <option value="COM">COMFORSER</option>
                      <option value="SPO">SPORTIVA</option>
                      <option value="VEE">VEERUBBER</option>
                      <option value="KUM">KUMHO</option>
                      <option value="DAV">DAVANTI</option>
                      <option value="KEN">KENDA</option>
                      <option value="YOK">YOKOHAMA</option>
                      <option value="ROA">ROADMARCH</option>
                      <option value="FAL">FALKEN</option>
                      <option value="VRE">VREDESTEIN</option>
                      <option value="LAS">LASSA</option>
                      <option value="WAN">WANDA</option>
                      <option value="GOO">GOODYEAR</option>
                      <option value="APL">APLUS</option>
                      <option value="MIT">MITAS</option>
                      <option value="NOR">NORTENHA</option>
                      <option value="NEX">NEXEN</option>
                      <option value="MAB">MABOR</option>
                      <option value="EVE">EVERGREEN</option>
                      <option value="SAI">SAILUN</option>
                      <option value="SEM">SEMPERIT</option>
                      <option value="ROS">ROSAVA</option>
                      <option value="TOY">TOYO</option>
                      <option value="NOK">NOKIAN</option>
                      <option value="TA">TAURUS</option>
                      <option value="ROT">ROTALLA</option>
                      <option value="INS">INSA-TURBO</option>
                      <option value="LEA">LEAO</option>
                      <option value="VIK">VIKING</option>
                      <option value="MAX">MAXXIS</option>
                      <option value="COO">COOPER</option>
                      <option value="NAN">NANKANG</option>
                      <option value="MET">METZELER</option>
                      <option value="FIR">FIRESTONE</option>
                    </select>
                  </div>
                </div>
                <div id="twoi" className="twoinput3">
                  <div className="text-block-7">Ár kategória :</div>
                  <div className="twoibput2">
                    <input value={searchParamsMain.minP}  onChange={handleChangeMinP} className="minpinp w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="Min. Ár" type="number" id="minpinp" required=""/>
                    <input value={searchParamsMain.maxP}  onChange={handleChangeMaxP} className="maxpinp w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="Max. Ár" type="number" id="maxpinp" required=""/>
                  </div>
                </div>
                <div className="div-block-2">
                  <div className="div-block-14">
                    <div className="text-block-8">Rendezés:</div>
                    <select value={searchParamsMain.typefilter}  onChange={handleChangeSort} id="sortselect" name="field" data-name="Field" className="sortselect w-select">
                      <option value="0">ID</option>
                      <option value="1">Cikkszám</option>
                      <option value="2">Megnevezés</option>
                      <option value="3">Gyártó Kód</option>
                      <option value="4">Gyártó Megnevezés</option>
                      <option value="5">Mintázat</option>
                      <option value="6">Szélesség</option>
                      <option value="7">Keresztmetszet</option>
                      <option value="8">Átmérő</option>
                      <option value="9">Lista Ár</option>
                      <option value="10">Készlet</option>
                      <option value="11">Engedmény</option>
                      <option value="12">Cikkcsoport Kód</option>
                      <option value="13">Cikkcsoport Megnevezés</option>
                      <option value="14">LI</option>
                      <option value="15">SI</option>
                      <option value="16">Fogyasztás</option>
                      <option value="17">Fékézés</option>
                      <option value="18">Gördülési Zaj</option>
                      <option value="19">Origin</option>
                      <option value="20">EAN</option>
                      <option value="21">Típus</option>
                      <option value="22">Súly</option>
                      <option value="23">Terméktípus</option>
                      <option value="24">Nettó Átadási Ár</option>
                      <option value="25">Bruttó Átadási Ár</option>
                      <option value="26">Akciós</option>
                      <option value="27">Eladási ár</option>
                    </select>
                  </div>
                  <div>
                    <div className="text-block-9">Csökkenő:</div><label className="w-checkbox checkbox-field">
                    <input value={searchParamsMain.filterdesc}  onChange={handleChangeDesc} type="checkbox" id="desccheck" name="checkbox" data-name="Checkbox" className="w-checkbox-input desccheck"/><span className="checkbox-label w-form-label" for="checkbox"> </span></label>
                  </div>
                  <div className="div-block-16">
                    <div className="text-block-10">Évszak:</div><select id="szezinp" name="field" data-name="Field" className="szezinp w-select">
                      <option value="0">-</option>
                      <option value="1">Téli</option>
                      <option value="2">Nyári</option>
                      <option value="4">Négyévszakos</option>
                      <option value="4">Egyéb?</option>
                    </select>
                  </div>
                </div>
                <div className="div-block-15">
                  <a onClick={searchButton} id="searchbutton" className="advancedfilterbutt2">Keresés</a>
                </div>
              </div>
              <div id="advoptcont" className="advoptcont">
                <div className="div-block-8">
                  <a id="advancedfilterbutt" onClick={toggleDropdown2} className="advancedfilterbutt w-button">Mutass többet! </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
    );
};
export default MainSearchBottom;