import React, {useEffect,useState} from 'react';
import './../css/webflow.css';
import './../css/beni2.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import foursesImg from '../../assets/images/fourseasgrey.png';
import winterImg from '../../assets/images/wintergrey.png';
import summerImg from '../../assets/images/summergrey.png';
import gaspumpImg from '../../assets/images/gaspump.png';
import noimageImg from '../../assets/images/noimage.png';
import rainImgsrc from '../../assets/images/rain.png';
import volumeImg from '../../assets/images/volume.png';
import { useTermin } from '../termkemintacomp/TerminContext';
import { useAuth } from './AuthContext';

const MainSuggestedProducts = () => {
    const [data, setData] = useState([]);
    const {loadData} = useTermin();
    const {selectPage} = useAuth();

  const fetchData = async () => {
    try {
      const url = new URL('http://95.138.193.55:41809/getSuggested');
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Items array is missing or invalid in the response');
      }
      console.log(data); // or handle data further
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  // useEffect to simulate componentDidMount
  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  useEffect(() => {
    console.log('Script loaded successfully');
    if (data.length > 0) {
        renderCards({ items: data });
    }

}, [data]);

function renderCards(data) {
    const style = document.createElement('style');
    let animationActive = false;  // Flag to control the animation start
    document.head.appendChild(style);
    style.textContent = `
        .card:hover {
            transform: translateY(-5px) rotateX(5deg);
            box-shadow: 0 10px 20px rgba(255, 0, 0, 0.5); /* Red shadow */
        }
    `;
    const carousel = document.getElementById('carousel');

        function populateCarousel(items) {
            items.items.forEach(item => {
                const card = createCardElement(item);
                carousel.appendChild(card);
            });
            // Duplicate items for seamless looping
            items.items.forEach(item => {
                const card = createCardElement(item);
                carousel.appendChild(card);
            });
        }

        populateCarousel(data);

        // Handling hover to pause and resume
        carousel.addEventListener('mouseover', () => {
            carousel.style.animationPlayState = 'paused';
        });

        carousel.addEventListener('mouseout', () => {
            carousel.style.animationPlayState = 'running';
        });
        function createCardElement(item) {
        function GetSeasonImg(season) {
        if(season === 1) {return winterImg}
        else if (season === 2) {return summerImg}
        else {return foursesImg}
        }
        function GetSeason(season) {
        if(season === 1) {return "Téli"}
        else if (season === 2) {return "Nyári"}
        else {return "Négyévszakos"}
        }
        function GetRaktColor(num) {
        if (num > 4) {return "green"}
        else if (num < 5 && num > 0) {return "orange"}
        else {return "red"}
        }
        function GetRakt(num) {
            if (num > 4) {return `Raktárom: (${num} db) `}
        else if (num < 5 && num > 0) {return `Utolsó ${num} db `}
        else {return "Elfogyott"}
        }
        function Getimgurl(item) {
            if (item.imgurl === "N/A") {return noimageImg}
        else {return item.imgurl}
        }
        const card = document.createElement('div');
        card.className = 'card';
        const imgdiv = document.createElement('div');
        imgdiv.className = 'card-image-container';
        card.appendChild(imgdiv);
        
        const img = document.createElement('img');
        img.src = Getimgurl(item);
        img.alt = 'product';
        img.className = 'card-image';
        img.onError = (e) => e.target.style.display = 'none';
        imgdiv.appendChild(img);
        
        const divid = document.createElement('hr');
        divid.className = 'divider';
        card.appendChild(divid);
        
        const info = document.createElement('div');
        info.className = 'info-section grey-background';
        const seasonDiv = document.createElement('div');
        const seasonImg = document.createElement('img');
        seasonImg.src = GetSeasonImg(item.szezon);
        seasonImg.style.transform = "translateY(1px)";
        seasonImg.style.maxHeight = '16px';
                seasonImg.style.maxWidth = '16px';
        seasonDiv.textContent = GetSeason(item.szezon);
        const consumptionDiv = document.createElement('div');
        const pumpImg = document.createElement('img');
        pumpImg.src = gaspumpImg;
        pumpImg.style.maxHeight = '15px';
                pumpImg.style.maxWidth = '15px';
        pumpImg.style.transform = "translateY(2px)";
        consumptionDiv.textContent = `${item.fogyasztas || 'N/A'}`;
        const brakingDiv = document.createElement('div');
        const rainImg = document.createElement('img');
        rainImg.src = rainImgsrc;
        rainImg.style.maxHeight = '15px';
                rainImg.style.maxWidth = '15px';
        rainImg.style.transform = "translateY(2.5px)";
        brakingDiv.textContent = `${item.fekezes || 'N/A'}`;
        const noiseDiv = document.createElement('div');
        const volImg = document.createElement('img');
        volImg.src = volumeImg;
        volImg.style.maxHeight = '15px';
                volImg.style.maxWidth = '15px';
        volImg.style.transform = "translateY(2px)";
        noiseDiv.textContent = `${item.gordZaj || 'N/A'}`;
        info.appendChild(seasonImg);
        info.appendChild(seasonDiv);
        info.appendChild(pumpImg);
        info.appendChild(consumptionDiv);
        info.appendChild(rainImg);
        info.appendChild(brakingDiv);
        info.appendChild(volImg);
        info.appendChild(noiseDiv);
        card.appendChild(info);
        
        const moreInfo = document.createElement('div');
        moreInfo.className = 'info-section';
        const nameLabel = document.createElement('h3');
        nameLabel.textContent = item.gyartoMegnevezes;
        nameLabel.style.font = '22px "Open Sans", sans-serif';
        nameLabel.style.marginBottom = "5px"; 
        nameLabel.style.fontWeight = "800";
        nameLabel.style.fontStyle = "italic";
        const manufacturerCodeDiv = document.createElement('div');
        manufacturerCodeDiv.textContent = item.mintazat;
        const itemNumberDiv = document.createElement('div');
        itemNumberDiv.textContent = item.formazottmegnevezes;
        manufacturerCodeDiv.style.fontSize = "14px";
        manufacturerCodeDiv.style.color = "#28353e";
        manufacturerCodeDiv.style.marginTop = "5px"; 

        itemNumberDiv.style.fontSize = "14px"; 
        itemNumberDiv.style.color = "#28353e";
        itemNumberDiv.style.marginTop = "5px"; 
        itemNumberDiv.style.height = "40px";
        const tipDiv = document.createElement('div');
        tipDiv.textContent = item.cikkcsoportMegnevezes;
        tipDiv.style.fontSize = "12px";
        tipDiv.style.fontWeight = "600";
        tipDiv.style.color = "#a4a4a4";
        tipDiv.style.marginTop = "10px";
        tipDiv.style.marginBottom = "5px";
        const raktDiv = document.createElement('div');
        const raktcDiv = document.createElement('div');
        const rakttextDiv = document.createElement('div');
        rakttextDiv.textContent = GetRakt(item.keszlet);
        rakttextDiv.style.color = GetRaktColor(item.keszlet);
        rakttextDiv.style.marginLeft = "10px";
        raktDiv.style.marginBottom = "30px";
        raktDiv.style.display = "flex";
                raktDiv.style.flexDirection = "row";
        raktDiv.style.alignItems = "center";
        raktDiv.style.marginTop = "10px";
        raktcDiv.style.height = "8px";
                raktcDiv.style.width = "8px";
                raktcDiv.style.backgroundColor = GetRaktColor(item.keszlet);
                raktcDiv.style.borderRadius = "20px";
        raktDiv.appendChild(raktcDiv);
        raktDiv.appendChild(rakttextDiv);
        const priceDiv = document.createElement('div');
        priceDiv.textContent = `${item.formazottAr} Ft`;
        priceDiv.style.fontSize = "28px"; 
        priceDiv.style.fontWeight = "800"; 
        priceDiv.style.fontStyle = "italic";
        priceDiv.style.color = "#bb0808"; 
        priceDiv.style.marginBottom = "10px";
        moreInfo.appendChild(nameLabel);
        moreInfo.appendChild(manufacturerCodeDiv);
        moreInfo.appendChild(itemNumberDiv);
        moreInfo.appendChild(tipDiv);
        moreInfo.appendChild(raktDiv);
        moreInfo.appendChild(priceDiv);
        card.appendChild(moreInfo);
        
        const button = document.createElement('button');
        button.className = 'buy-button';
        button.textContent = 'Kosárba';
        button.style.color = "#fff";
        button.style.backgroundColor = "#bb0808";
        button.style.borderColor = "#6e0000";
        button.style.boxShadow = "0 4px 0 #6e0000";
        button.style.display = "block";
        button.style.width = "100%";
        button.style.fontWeight = "700";
        button.style.textAlign = "center";
        button.style.verticalAlign = "middle";
        button.style.border = "1px solid transparent";
        button.style.padding = "10px 35px";
        button.style.fontSize = "1rem";
        button.style.lineHeight = "1.5";
        button.style.borderRadius = "5px";
        button.style.transition = "color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out";
        button.addEventListener('click', async function() {
            const Cikkszam = item.cikkszam; 
            console.log(Cikkszam);
            const response = await fetch(`http://l95.138.193.55:41809/productId?cikkszam=${Cikkszam}`);
            const result = await response.json();
            loadData(result);
            window.scrollTo(0, 0);
            selectPage("Termin");

            });
        
        card.appendChild(button);
        return card;
    }
};
    return (
        <div className="div-block-23">
          <div id="carousel" className="carousel"></div>
        </div>
    );
};
export default MainSuggestedProducts;