import React, { createContext, useState,useEffect, useContext } from 'react';

const TerminContext = createContext(null);

export const useTermin = () => useContext(TerminContext);

export const TerminProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [openedPay,setOpenedPay] = useState(false);
    const [payPrice,setPayPrice] = useState(0);
    const [rpopen,setRpOpen] = useState(false);
    const [payColor,setPayColor] = useState("red");
    const [payText,setPayText] = useState("ERROR");
     
    const setPayDetalis = (text,color) => {
        setPayText(text);
        setPayColor(color);
    }

    const toggleRp = () => {
        setRpOpen(!rpopen);
    }

    const setPrice = (price) => {
        setPayPrice(price);
    }

    const togglePay = () =>{
        setOpenedPay(!openedPay);
        console.log("paxpanel - "+openedPay);
    }

    const loadData = (data) => {
        setData(data);
    }

    // Context value that will be passed to consumers
    const contextValue = {
        data,loadData,openedPay,togglePay,payPrice,setPrice,rpopen,toggleRp,
        payColor,payText,setPayDetalis
        
    };

    return <TerminContext.Provider value={contextValue}>{children}</TerminContext.Provider>;
};