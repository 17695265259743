import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'

const MainSuggestedProductsTitle = () => {

    return (
        <div className="div-block-13">
          <div className="div-block-9">
            <div className="div-block-55">
              <div className="napszmar">Kiemelt termékeink</div>
            </div>
            <div className="kiemeltcsik">
              <div className="div-block-10"></div>
            </div>
          </div>
        </div>
    );
};
export default MainSuggestedProductsTitle;