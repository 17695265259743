import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/paypopup.css';
import './../css/normalize.css';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import CheckoutForm from '../cart/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_51OpUvdAU5rE1Ep1wEGd5DWqJJGyqPdKNYazkXO1cBBwRM46EasRUj9CMSOHbEtRNBtTewct3w05YCQSD5AMBeOMr00llKlvWZ3');

const MainProfilePopup = () => {

    return (
        <div style={{opacity:1}} className="profil-popup-wrapper">
      <div className="popup-container">
        <div className="popupelement popupmodal">
          <div className="ms2-modal-row">
            <div className="ms2-modal-na">
            <button className="close-button" >×</button>
            <div className='stripeholder'>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default MainProfilePopup;