import React, {useState} from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import profileicon from '../../assets/images/profileicon.png'
import MainAccountPopup from './MainAccountPopup';
import cart from '../../assets/images/cart.png'
import { useAuth } from './AuthContext';


const MainHeadBar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false); // This should actually be derived from your auth logic

    const {isPopupVisible,togglePopup,selected,selectPage,changeNav,openNav } = useAuth();
    
    return (
        <div id="TopBar" className="topbar">
      <div className="topbarmid">
        <a href="#" className="div-block-56 w-inline-block"><img src="C:\Users\leran\gmacorp\gmacorp\pannontires\public/imageslogoblack.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 11vw, 14vw" srcset="images/logoblack-p-500.png 500w, images/logoblack-p-800.png 800w, images/logoblack-p-1080.png 1080w, images/logoblack-p-1600.png 1600w, images/logoblack.png 1890w" alt="" className="image-42"/></a>
        <div className="div-block-50">
          <div id="t" className="tittleholder" >
            <div className="titlelieholder">
              <div id="border1" className="titleline"></div>
            </div>
            <a id="button1" onClick={() =>selectPage("Main")} className="menubuttonact w-button">Pannon Tires</a>
          </div>
          <div id="t" className="tittleholder"  >
            <div className="titlelieholder">
              <div id="border2" className="titleline"></div>
            </div>
            <a id="button2" onClick={() =>selectPage("Termek")} className="menubutton w-button">Termékeink</a>
          </div>
          <div id="t" className="tittleholder">
            <div className="titlelieholder">
              <div id="border3" className="titleline"></div>
            </div>
            <a id="button3" href="#rolunkblock" className="menubutton w-button">Rólunk</a>
          </div>
          <div id="t" className="tittleholder">
            <div className="titlelieholder">
              <div id="border4" className="titleline"></div>
            </div>
            <a id="button4" href="#kapcstitle" className="menubutton w-button">Kapcsolat</a>
          </div>
          <img src={profileicon} onClick={togglePopup} loading="lazy" sizes="(max-width: 479px) 73.05208587646484px, (max-width: 767px) 15vw, 100px" alt="" className="image-41"/>
          {isPopupVisible && (
                    <MainAccountPopup
                        isLoggedIn={isLoggedIn}
                    />
                )}
          <div className="cartholder"><img src={cart} onClick={openNav} loading="lazy" sizes="(max-width: 479px) 73.05208587646484px, (max-width: 767px) 15vw, 100px" srcset="images/shopping_cart_PNG38.png 500w, images/shopping_cart_PNG38.png 800w, images/shopping_cart_PNG38.png 1024w" alt="" className="cartimage"/>
            <div>0</div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default MainHeadBar;