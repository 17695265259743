import React, {useState,useEffect} from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import { useTermin } from './TerminContext';
import foursesImg from '../../assets/images/fourseasgrey.png';
import winterImg from '../../assets/images/wintergrey.png';
import summerImg from '../../assets/images/summergrey.png';
import gaspumpImg from '../../assets/images/gaspump.png';
import noimageImg from '../../assets/images/noimage.png';
import rainImgsrc from '../../assets/images/rain.png';
import volumeImg from '../../assets/images/volume.png';
import { useCart } from '../cart/CartContext';
import { useAuth } from '../mainpagecomp/AuthContext';
import cart from '../../assets/images/cart2.png'

const TerminTireInfo = () => {
    const {data} = useTermin();
    const [keszcolor,setColor] = useState('green');
    const [keszletText,setKeszletText] = useState("");
    const [amount,setAmount] = useState(4);
    const {cartData,removeBasketData,addBasketData} = useCart();
    const {changeNav} = useAuth();
    const {openedPay,togglePay} = useTermin();

    const putToCart = () => {
      addBasketData(data.cikkszam,amount);
      changeNav();
    }

    const addAmount = (keszlet) => {
      if(amount<keszlet){setAmount(amount+1)}
    }

    const remAmount = () => {
      if(amount>1){setAmount(amount-1);}
    }

    useEffect(() => {
      const getKeszletText = (keszlet) => {
        if (keszlet <= 0) {
          setColor('red');
          setKeszletText("Nincs Raktáron");
        } else if (keszlet <= 10) {
          setColor('orange');
          setKeszletText(`Utolsó ${keszlet} darab`);
        } else {
          setColor('green');
          setKeszletText(`Raktáron (${keszlet} darab)`);
        }
      };
  
      getKeszletText(data.keszlet);
      if (data.keszlet < 4) {setAmount(data.keszlet)} 
    }, [data.keszlet]);

    const getImageUrl = (item) => {
      return item.imgurl === "N/A" ? noimageImg : item.imgurl;
  };

  // Function to get season image
  const getSeasonImg = (season) => {
      switch (season) {
          case 1:
              return winterImg;
          case 2:
              return summerImg;
          default:
              return foursesImg;
      }
  };

  // Function to get season text
  const getSeason = (season) => {
      switch (season) {
          case 1:
              return "Téli";
          case 2:
              return "Nyári";
          default:
              return "Négyévszakos";
      }
  };

    return (
        <div class="gumi-info">
      <div class="w-layout-grid gyorsnezet">
        <div id="w-node-_45c43c22-101a-3aff-5e98-ffa2aa32146c-78f34e8b" class="k-p-infok">
          <div class="idejonakepsenedek">
                <div className="card-image-container1">
                    <img className="card-image1" src={getImageUrl(data)} alt="product" onError={(e) => e.target.style.display = 'none'} />
                </div>
                <hr className="divider1" />
                <div className="info-section grey-background">
                    <img src={getSeasonImg(data.szezon)} style={{ maxHeight: '16px', maxWidth: '16px', transform: "translateY(1px)" }} />
                    <div>{getSeason(data.szezon)}</div>
                    <img src={gaspumpImg} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2px)" }} />
                    <div>{data.fogyasztas || 'N/A'}</div>
                    <img src={rainImgsrc} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2.5px)" }} />
                    <div>{data.fekezes || 'N/A'}</div>
                    <img src={volumeImg} style={{ maxHeight: '15px', maxWidth: '15px', transform: "translateY(2px)" }} />
                    <div>{data.gordZaj || 'N/A'}</div>
                </div>
          </div>
        </div>
        <div id="w-node-_45c43c22-101a-3aff-5e98-ffa2aa32146e-78f34e8b" class="rovidleirasdiv">
          <h1 class="marka"><strong><em>{data.gyartoMegnevezes}</em></strong></h1>
          <div class="gumi-alnev">{data.mintazat}<br/>{data.formazottmegnevezes}<br/></div>
          <div class="fajta"><strong>{data.cikkcsoportMegnevezes}<br/></strong></div>
          <div class="raktar"><strong style={{color: keszcolor}}>• {keszletText}</strong></div>
          <h1><strong><em class="italic-text">{data.formazottAr} Ft</em></strong></h1>
          <div class="button-holder">
            <div class="mennyiseg">
              <div class="form-block-3 w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-5" data-wf-page-id="662965345faa3d7178f34e8b" data-wf-element-id="45c43c22-101a-3aff-5e98-ffa2aa321486">
                  <a onClick={remAmount} class="button-5 w-button">-1</a>
                    <input value={amount} class="text-field w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="4" type="text" id="field-2" required=""/>
                  <a onClick={()=>addAmount(data.keszlet)} class="button-4 w-button">+1</a>
                </form>
                <div class="w-form-done"></div>
                <div class="w-form-fail"></div>
              </div>
            </div>
            <a onClick={putToCart} data-w-id="45c43c22-101a-3aff-5e98-ffa2aa32148e" style={{backgroundColor:'rgb(187,8,8)'}} href="#" class="kosargomb w-inline-block">
              <div class="kosar">
                  <img src={cart} loading="lazy" id="kosargombimage" alt="" class="kosarkep"/>
                <div class="kosarbateszem">Kosárba teszem</div>
              </div>
            </a>
          </div>
          <div class="salalalalomsz-veg">
            <div class="elvalasztovonal"></div>
            <div class="kisbetusresz">A feltüntetett árak bruttóban és darabban értendők és csak online rendelés esetén érvényesek. A termék képei csak illusztrációk és egyes esetekben eltérhetnek a valóságtól!</div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default TerminTireInfo;