import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import TermekSearchBottom from './TermekSearchBottom';
import TermekList from './TermekList';
//import TermekSuggestedProductsTitle from './TermekSuggestedProductsTitle';
//import TermekSuggestedProducts from './TermekSuggestedProducts';
//import TermekAboutUs from './TermekAboutUs';
//import TermekTopBrands from './TermekTopBrands';
//import TermekWhyUs from './TermekWhyUs';
import TermekContactUs from './TermekContactUs';

const TermekMainContainer = () => {

    return (
        <div className="maincont">
      <div id="card-container" className="maincardcontainer">
        <TermekSearchBottom/>
        <TermekList/>
        <TermekContactUs/>
        <div></div>
      </div>
    </div>
    );
};
export default TermekMainContainer;