import React from 'react';
import './../css/webflow.css';
import './../css/beni-d-dceb8b316d2f6393e86418f0a92c546a.webflow.css';
import './../css/normalize.css';
import MapComponent from '../MapComponent'
import twitter from '../../assets/images/twitter.png'
import face from '../../assets/images/facebook.png'
import insta from '../../assets/images/insta.png'
import linkedin from '../../assets/images/linkedin.png'
import youtube from '../../assets/images/youtube.png'
import tiktok from '../../assets/images/tiktok.png'

const MainContactUs = () => {

    return (
        <div className="kapcsolat">
          <div className="kapcstitle" id='kapcstitle'>
            <div className="dasdasfsa">
              <div className="ffasfafasf">Kapcsolat</div>
            </div>
            <div className="fsafasfasfas">
              <div className="safsafassg"></div>
            </div>
          </div>
          <div id="others" className="kapcsmaindiv">
            <div className="div-block-52">
              <div className="div-block-54">
                <div className="butoonsdiv">
                  <a className="button-2 w-button">E-mail: info@pannontires.com</a>
                  <a href="tel:+36303124600" className="button-2-copy w-button">Telefon: +36 30 312 4600</a>
                  <div className="div-block-33">
                    <a href="https://www.instagram.com/" className="w-inline-block"><img src={insta} loading="lazy" sizes="30.02083396911621px" srcset="images/insta-p-500.png 500w, images/insta-p-800.png 800w, images/insta-p-1080.png 1080w, images/insta.png 1200w" alt="" className="image-4"/></a>
                    <a href="https://www.facebook.com/" className="w-inline-block"><img src={face} loading="lazy" sizes="41px" srcset="images/facebook-p-500.png 500w, images/facebook-p-800.png 800w, images/facebook-p-1080.png 1080w, images/facebook-p-1600.png 1600w, images/facebook-p-2000.png 2000w, images/facebook-p-2600.png 2600w, images/facebook-p-3200.png 3200w, images/facebook.png 4096w" alt="" className="img2"/></a>
                    <a href="https://twitter.com/" className="w-inline-block"><img src={twitter} loading="lazy" sizes="35px"   alt="" className="img2" style={{height : '30px'}}/></a>
                    <a href="https://il.linkedin.com/" className="w-inline-block"><img src={linkedin} loading="lazy" sizes="35px"   alt="" className="img2"/></a>
                    <a href="https://www.youtube.com/" className="w-inline-block"><img src={youtube} loading="lazy" sizes="35px"   alt="" className="img2" style={{height : '30px'}}/></a>
                    <a href="https://www.tiktok.com/" className="w-inline-block"><img src={tiktok} loading="lazy" sizes="45px" srcset="images/tiktok-p-500.png 500w, images/tiktok.png 800w" alt="" className="img6"/></a>
                  </div>
                  <a href="#" className="button-2-co w-button">Szállítás és visszatérítés</a>
                  <a href="#" className="button-2-co-copy w-button">ÁSZF</a>
                  <a href="#" className="button-2-co-copy-copy w-button">Adatkezelés és sütik</a>
                </div>
              </div>
              <div className="div-block-53">
                <div><MapComponent/></div>
                {/*<div className="map-2 w-widget w-widget-map" data-widget-style="roadmap" data-widget-latlng="46.54766650000001,17.1168841" aria-label="Pannon Tires" data-enable-scroll="true" role="region" title="Pannon Tires" data-enable-touch="true" data-widget-zoom="12" data-widget-tooltip=""></div>*/}
              </div>
            </div>
          </div>
          <section className="footer-subscribe">
            <div className="container">
              <div className="footer-form-two w-form">
                <form id="wf-form-Footer-Form-Two" name="wf-form-Footer-Form-Two" data-name="Footer Form Two" method="get" className="footer-form-container-two" data-wf-page-id="6620f5731fb95a6b89fec1cd" data-wf-element-id="1b263833-2853-0f6c-c5f6-4d6f6f7ec9ef">
                  <div className="footer-form-title">Iratkozz fel a Pannon Tires hírlevelére</div>
                  <div className="footer-form-block-two"><input className="footer-form-input w-input" maxlength="256" name="Footer-Email-Two" data-name="Footer Email Two" aria-label="Enter your email" placeholder="Email" type="email" id="Footer-Email-Two" required=""/><input type="submit" data-wait="Kérem várjon..." className="button-primary footer-form-button w-button" value="Feliratkozás"/></div>
                </form>
              </div>
            </div>
          </section>
        </div>
    );
};
export default MainContactUs;