import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useTermin } from '../termkemintacomp/TerminContext';
import { useAuth } from '../mainpagecomp/AuthContext';

const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {toggleRp,payColor,payText,setPayDetalis,togglePay} = useTermin();
  const {selectPage} = useAuth();
  const [isFormValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    addressLine1: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });

  useEffect(() => {
    // Check if all form data fields are filled and not just blank
    const isValid = Object.values(formData).every(x => x.trim() !== '');
    setFormValid(isValid);
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !isFormValid) {
      if (!isFormValid || !elements) {
        togglePay();
        setPayDetalis("Tölts ki mindenmezőt legközelebb!","red");
            toggleRp();
      } 
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createSource(cardElement);

    if (result.error) {
      console.error(result.error.message);
    } else {
      const sourceId = result.source.id;
      const chargeDetails = {
        source: sourceId,
        amount: amount * 100,
        shippingName: formData.fullName,
        addressLine1: formData.addressLine1,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        country: formData.country,
        desc: "",
      };
      togglePay();
      axios.post('http://95.138.193.55:41809/api/payments/charge', chargeDetails)
        .then(response => {
          console.log('Payment Success:', response);
          //setPayDetalis("Siker! Köszönjük a vásárlást","green");
          selectPage("Succes");
            toggleRp();
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              setPayDetalis("Bankártya adatok helytelenek","red");
              toggleRp();
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
              setPayDetalis("Hibás kérés!","red");
              toggleRp();
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
              setPayDetalis("Szerver válasz hiba!","red");
              toggleRp();
          }
      });
    }
  };

  // Custom styling for the Stripe CardElement
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        '::placeholder': {
          color: '#aab7c4'
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  return (
    <form className='payment-form' onSubmit={handleSubmit}>
      <div className="shipping-details">
        <h2>Shipping Details</h2>
        <input type="text" name="fullName" placeholder="Full Name" required value={formData.fullName} onChange={handleInputChange} />
        <input type="text" name="addressLine1" placeholder="Address Line 1" required value={formData.addressLine1} onChange={handleInputChange} />
        <input type="text" name="city" placeholder="City" required value={formData.city} onChange={handleInputChange} />
        <input type="text" name="state" placeholder="State" required value={formData.state} onChange={handleInputChange} />
        <input type="text" name="postalCode" placeholder="Postal Code" required value={formData.postalCode} onChange={handleInputChange} />
        <input type="text" name="country" placeholder="Country" required value={formData.country} onChange={handleInputChange} />
      </div>
      <div className="popupprice">
        <h2 style={{color:'#bb0808'}}>{Intl.NumberFormat('fr-FR').format(amount)} Ft</h2>
      </div>
      <div className="card-details">
        <h2>Payment Information</h2>
        <CardElement options={cardStyle} />
      </div>
      <button type="submit" className="pay-button" disabled={!isFormValid || !stripe}>Submit Payment</button>
    </form>
  );
};

export default CheckoutForm;
