import './App.css';
import MainPage from './components/pages/MainPage';
import TermekPage from './components/pages/TermekPage';
import TermekMintaPage from './components/pages/TermekMintaPage';
import React from 'react';
import { useAuth } from './components/mainpagecomp/AuthContext';
import KosarPage from './components/pages/KosarPage';
import {Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SuccesPage from './components/pages/SuccesPage';

const stripePromise = loadStripe('pk_live_51OpUvdAU5rE1Ep1wurdfY2mhAmlAaqNRtVbqKvsvLGUiXADjf2i0j1U10fhZxmtvyhnxapv3Rm4MOWggldkPtbB800wlrxM33T');

function App() {
    const {selected} = useAuth();
    const pages = {
      Main: MainPage,
      Termek: TermekPage,
      Termin: TermekMintaPage,
      Kosar: KosarPage,
      Succes: SuccesPage
    };

    const CurrentPage = pages[selected] || null;
    return (
      <Elements stripe={stripePromise}>
    <div className="App">
      <header className="App-header">
      {CurrentPage ? <CurrentPage /> : <MainPage/>}
      
      </header>
      <body>
        
      </body>
    </div>
    </Elements>
  );
}

export default App;
